var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CreateWorkspacePage", {
    attrs: { "current-user": _vm.currentUser, creating: _vm.creating },
    on: { create: _vm.saveWorkspace }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }