








import CreateWorkspacePage from '@c/features/create-workspace-modal/pages/CreateWorkspacePage.vue'
// import { WORKSPACE_ROLES } from '@c/models/Workspace'
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
export default Vue.extend({
  name: 'WorkspaceCreateWrapper',
  components: { CreateWorkspacePage },
  data() {
    return {
      creating: false
    }
  },
  computed: {
    ...mapState({
      currentUser: (state) =>
        state.users.currentUser || state.users.currentWorkspaceMember
    })
  },
  methods: {
    ...mapActions(['createWorkspace', 'updateImage']),
    async saveWorkspace({ data }) {
      if (this.creating) return

      const { name, description, is_open, avatar } = data

      let workspace
      this.creating = true
      try {
        workspace = await this.createWorkspace({
          name,
          description,
          is_open
        })
      } catch (e) {
        this.$console.debug(e)
        return
      }

      if (avatar) {
        await this.updateImage({ id: workspace.uuid, image: avatar })
      }
      this.creating = false
      this.$router.push(`/workspace/${workspace.uuid}`)
      return workspace
    }
  }
})
