


















































import BareApplicationShell from '@c/features/app/pages/BareApplicationShell.vue'
import EditTab from '@c/features/workspace-settings-modal/pages/EditTab.vue'
import User from '@c/models/User'
import BackButton from '@c/shared/molecules/buttons/BackButton.vue'
import ContentWithHeader from '@c/shared/molecules/structural/blocks/ContentWithHeader.vue'
import { mapGetters } from 'vuex'
import Vue from 'vue'

export default Vue.extend({
  name: 'CreateWorkspacePage',
  components: { BackButton, EditTab, ContentWithHeader, BareApplicationShell },
  props: {
    currentUser: {
      type: Object as () => User,
      required: true
    },
    creating: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      byEmailJoin: true
    }
  },
  computed: {
    ...mapGetters(['isUman'])
  },
  methods: {
    newAvatar(val: File): void {
      if (val.size > 2 * 1000 * 1000) {
        this.$toast.danger(
          'Workspace image too large',
          'We support images up to 2MB, your image exceeded this amount. Please upload a smaller workspace avatar.'
        )
        return
      }
    }
  }
})
