var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BareApplicationShell",
    { attrs: { "current-user": _vm.currentUser } },
    [
      _c("div", { staticClass: "grid grid-center full" }, [
        _c(
          "div",
          {
            staticClass: "border subtle-primary rounded-border thick ph-8 pv-7"
          },
          [
            _vm.isUman
              ? _c(
                  "div",
                  [
                    _c("BackButton", {
                      attrs: { text: "Back to workspaces" },
                      on: {
                        click: function($event) {
                          return _vm.$router.back()
                        }
                      }
                    }),
                    _c(
                      "ContentWithHeader",
                      {
                        attrs: {
                          title: "Create a new workspace",
                          subtitle:
                            "We'll get you started on uman.ai in a few steps"
                        }
                      },
                      [
                        _c("EditTab", {
                          attrs: {
                            creating: _vm.creating,
                            "button-text": "Create workspace",
                            paddingless: ""
                          },
                          on: {
                            onEdit: function(ref) {
                              var name = ref.name
                              var description = ref.description
                              var is_open = ref.is_open
                              var avatar = ref.avatar

                              return _vm.$emit("create", {
                                data: {
                                  name: name,
                                  description: description,
                                  is_open: is_open,
                                  avatar: avatar
                                },
                                emailAllowed: _vm.byEmailJoin
                              })
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _c("div", { staticClass: "no-auth-container" }, [
                  _c("p", { staticClass: "no-auth-header" }, [
                    _vm._v(
                      " You are not allowed to create a new uman.ai workspace "
                    )
                  ]),
                  _c("p", { staticClass: "no-auth-sub" }, [
                    _vm._v(
                      " If you think this is incorrect, or you landed on this page accidentally. "
                    )
                  ]),
                  _c("p", { staticClass: "no-auth-sub" }, [
                    _vm._v(" Please contact us at "),
                    _c(
                      "a",
                      {
                        staticClass: "no-auth-link",
                        attrs: { href: "mailto:charles@uman.ai" }
                      },
                      [_vm._v("charles@uman.ai")]
                    )
                  ])
                ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }